@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
  padding: 0;
  background-color: #211e3f;
  color: white;
  font-weight: 400;
}
html {
  height: -webkit-fill-available;
}
